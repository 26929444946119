@use 'sass:math';
@mixin scalefont($value){

// include {} from 'math'

   $scale: math.div($value,14);
   $lineScale: math.div($value,14);
   $lineOffSet: 0.5rem;

  // font-size
  --mdc-typography-headline1-font-size: #{ $scale * 1rem * 96};
  --mdc-typography-headline2-font-size: #{ $scale * 1rem * 60};
  --mdc-typography-headline3-font-size: #{ $scale * 1rem * 48};
  --mdc-typography-headline4-font-size: #{ $scale * 1rem * 34};
  --mdc-typography-headline5-font-size: #{ $scale * 1rem * 24};
  --mdc-typography-headline6-font-size: #{ $scale * 1rem * 20};
  --mdc-typography-subtitle1-font-size: #{ $scale * 1rem * 16};
  --mdc-typography-subtitle2-font-size: #{ $scale * 1rem * 14};
  --mdc-typography-body1-font-size:     #{ $scale * 1rem * 16};
  --mdc-typography-body2-font-size:     #{ $scale * 1rem * 14};
  --mdc-typography-caption-font-size:   #{ $scale * 1rem * 14};
  --mdc-typography-button-font-size:    #{ $scale * 1rem * 12};
  --mdc-typography-overline-font-size:  #{ $scale * 1rem * 10};

  // line-height
  --mdc-typography-headline1-line-height: #{ $lineScale * (1rem + $lineOffSet) * 96};
  --mdc-typography-headline2-line-height: #{ $lineScale * (1rem + $lineOffSet) * 60};
  --mdc-typography-headline3-line-height: #{ $lineScale * (1rem + $lineOffSet) * 48};
  --mdc-typography-headline4-line-height: #{ $lineScale * (1rem + $lineOffSet) * 34};
  --mdc-typography-headline5-line-height: #{ $lineScale * (1rem + $lineOffSet) * 24};
  --mdc-typography-headline6-line-height: #{ $lineScale * (1rem + $lineOffSet) * 20};
  --mdc-typography-subtitle1-line-height: #{ $lineScale * (1rem + $lineOffSet) * 16};
  --mdc-typography-subtitle2-line-height: #{ $lineScale * (1rem + $lineOffSet) * 14};
  --mdc-typography-body1-line-height:     #{ $lineScale * (1rem + $lineOffSet) * 16};
  --mdc-typography-body2-line-height:     #{ $lineScale * (1rem + $lineOffSet) * 14};
  --mdc-typography-caption-line-height:   #{ $lineScale * (1rem + $lineOffSet) * 14};
  --mdc-typography-button-line-height:    #{ $lineScale * (1rem + $lineOffSet) * 12};
  --mdc-typography-overline-line-height:  #{ $lineScale * (1rem + $lineOffSet) * 10};

  //  --mdc-typography-headline1-font-family: Arial, Helvetica, sans-serif;
  //  --mdc-typography-button-text-transform: 16px;

}