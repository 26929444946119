.footer {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: var(--mdc-theme-on-secondary, #000000); 
   color: var(--mdc-theme-primary, #00949c);
   text-align: center;
   z-index: 1000;
}

.footer-commit-id {
   float: right;
   padding-right: 20px;
   color: darkgrey;
   font-style: italic;
}