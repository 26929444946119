// theming:
// https://material.io/develop/web/docs/theming/
// https://glitch.com/edit/#!/equal-prickly-weight?path=readme.md%3A1%3A0
// https://github.com/material-components/material-components-web/blob/master/docs/theming.md

// shape
$mdc-shape-small-component-radius: 4px;
$mdc-shape-medium-component-radius: 4px;
$mdc-shape-large-component-radius: 0px;
// color
$mdc-theme-primary: #00949c;
$mdc-theme-secondary: #9c1700;
$mdc-theme-background: #fff;

//$mdc-theme-surface: #ffffff;
//$mdc-theme-background: #ffffff;
//$mdc-theme-error: #b00020;

//$mdc-theme-on-primary: #ffffff;
//$mdc-theme-on-secondary: #ffffff;
//$mdc-theme-on-error: #ffffff;
//$mdc-theme-on-surface: #000000;


