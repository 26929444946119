/* BLOG */


@use "theme" as myTheme;

.blog-author{
	border-radius: 50%;
	width: 110px;
}
.blog-card {
	@media screen and (max-width: 700px) {
		width: 700px;
	}
	margin-left: auto;
	margin-right: auto;
}

.blog-card__primary {
  padding: 1rem;
}

.blog-card__title {
  margin: 0;
}

.blog-card__subtitle {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
  margin: 0;
}

.blog-card__secondary {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
  padding: 0 1rem 8px 1rem;
}

.blog-card-shaped {
  /* @noflip */
  border-radius: 24px 8px;
}
[dir=rtl] .blog-card-shaped, .blog-card-shaped[dir=rtl] {
  /* @noflip */
  border-radius: 8px 24px;
}

.blog-card__media.mdc-card__media--square {
  height: 110px;
  min-width: 110px;
}

.author-card__media.mdc-card__media--square {
   border-radius: 50%;
   margin-left: 1rem;
}

.design-horizontal .mdc-card__action-buttons,
.design-horizontal > div:first-child
{
  margin-left: 2rem;
  padding-left: 110px;
}

.design-with-header .mdc-card__secondary,
.design-text-over-media .mdc-card__secondary {
  padding-top: 1rem;
}

.design-text-over-media .mdc-card__media-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
      align-items: flex-end;
}

.design-text-over-media .blog-card__media-content .blog-card__title,
.design-text-over-media .blog-card__media-content .blog-card__subtitle {
  color: white;
}

.design-horizontal .mdc-card__primary-action {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}

///////////////////////

// https://css-tricks.com/snippets/css/fluid-typography/

// carousel
$carouselcontrolwidth: 100px;

.carousel-inner  {
  margin-left: $carouselcontrolwidth;
  margin-right: $carouselcontrolwidth;
  padding-left: 0px;
  width: unset;
}

.carousel-control-prev {
  color: blue;//$mdc-theme-primary;
}

.carousel-control-next {
  color: blue;//$mdc-theme-primary;
}

.carousel-indicators {
  margin-left: $carouselcontrolwidth;
  margin-right: $carouselcontrolwidth;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: myTheme.$mdc-theme-secondary; 
}

tr:nth-child(odd) {background-color: #f2f2f2;}

//tr:nth-child(even) {background-color: #00fff2;}
