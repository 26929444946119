@use "font.scss";
@use "theme.scss";
@use "../material/material.scss";
@use "scale-fonts.scss";
@use "footer.scss";
@use "blog.scss";
@use "login.scss";
@use "registry-tree.scss";
@use "openlayers.scss";
@use "multiRangeSlider.scss";
@use "dense-layout.scss";


/** GENERAL */
.app-body-base {
  height: 90vh;
  margin-left: auto;
  margin-right: auto;
  display: block;
  /*overflow-y: scroll;*/
}

.app-body {
	max-width: 900px;
	@extend .app-body-base;
}

.app-body-fullwidth{
	@extend .app-body-base;
} 
.alert-warning {
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
}
// fix menu behind map
.mdc-drawer {
	z-index: 6;
	z-index: 1500;
}


// modal

/* The Modal (background) */
/*
.modal {
  display: none; // Hidden by default 
  position: fixed; // Stay in place 
  z-index: 2000; // Sit on top 
  padding-top: 0px; //100px; Location of the box 
  left: 0;
  top: 0;
  width: 100%; // Full width 
  height: 100%; // Full height 
  overflow: auto; // Enable scroll if needed 
  background-color: rgb(0,0,0); // Fallback color 
  background-color: rgba(0,0,0,0.95); // Black w/ opacity 
}
*/

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
