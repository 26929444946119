@use "../general/scale-fonts" as scalefont;

@mixin styles() {
	.footer-commit-id {
		color: #EC7063; // rot // desktop
	}

	html {
		@include scalefont.scalefont(1);
	}

	// fix height for pagination cells with material icons
	.page-link.material-icons {
		font-size: 20px;
	}
}