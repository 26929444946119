.registry-tree-data-table {
min-height: 100%;
}

.registry-tree-data-table .mdc-data-table__row{
	border-top-width: 0px;
}

.registry-tree-data-table .even-indent{
//	 background-color: snow;
	 background-color: ghostwhite;
}


.tree-list  {
  list-style-type: none;
  padding-left: 10px;
}

.tree-node-icon{
  cursor: pointer;
  user-select: none;
}

/* Hide the nested list */
.tree-nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.tree-active {
  display: block;
} 


td:not(.portal-nowrap){
	white-space: normal;
}

.row-collapsed {
	visibility: collapse;
}

