@use "../general/scale-fonts" as scalefont;

@mixin styles() {
	.footer-commit-id {
		color: #DC7633; // orange
	}

	html {
		@include scalefont.scalefont(2);
	}
}