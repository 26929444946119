@use  "general/general"; 


// Media Imports
@use "mobile/mobile" as mobile;
@use "tablet/tablet" as tablet;
@use "desktop/desktop" as desktop;

@use "wip";

@use "~ol/ol";
 //web-builder/node_modules/ol/ol.css

// mobile screen or even smaller
@media (min-width:1px) {
	@include mobile.styles();
}

// tablet screen
@media (min-width:800px) {
	@include tablet.styles();
}
// extra desktop screen
@media (min-width:1000px) {
	@include desktop.styles();
}
// extra wide screen
@media screen and (min-width: 1500px)  {
}


 
