@use "../general/scale-fonts" as scalefont;
@mixin styles() {
	.footer-commit-id {
		color: #F7DC6F; // Gelb für mobile oder kleinere Bildschirme
	}

	html {
		@include scalefont.scalefont(3); // Font-Skalierung anwenden
	}
}
