// blog slides

/* Standardbreite für mobile Geräte */
.blogSlide {
    width: 400px;
    height: 200px;
}

@media (min-width: 800px) {
    /* Größere Breite für Tablets und mittlere Bildschirme */
    .blogSlide {
        width: 600px;
        height: 300px;
    }
}

@media (min-width: 1000px) {
    /* Kleinere Breite für sehr große Bildschirme */
    .blogSlide {
        width: 400px;
        height: 200px;
    }
}