@use "@material/button";

/* LOGIN */ 
.avd-login-header{
	text-align: center;
	width: 100%;
}
.avd-login-footer{
	text-align: center;
	width: 100%;
}
.avd-login-form-field{
	width: 100%;
}

.avd-login-elevation {
	padding: 20px;
}
.avd-login-submitButton{
//  @extend .mdc-button--outlined
  @extend .mdc-button--raised !optional;
//  @extend .mdc-button--unelevated
	margin:0 auto; // center div elements
	display:block;  // center div elements
}
