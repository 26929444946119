// https://github.com/material-components/material-components-web/tree/master/packages/mdc-theme#css-custom-properties

@use "../general/theme" as myTheme;

@use "@material/theme" with (
  $primary: myTheme.$mdc-theme-primary,
  $secondary: myTheme.$mdc-theme-secondary,
  $background: myTheme.$mdc-theme-background,
);


@use "@material/typography/mdc-typography";

// @use "material-components-web";

@use "@material/layout-grid/mdc-layout-grid";

@use "@material/button/mdc-button";
@use "@material/button";

@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/icon-button/mdc-icon-button";

@use "@material/card/mdc-card";

// Tab Bar
@use "@material/tab-bar/mdc-tab-bar";
@use "@material/tab-scroller/mdc-tab-scroller";
@use "@material/tab-indicator/mdc-tab-indicator";
@use "@material/tab/mdc-tab";

@use "@material/textfield/mdc-text-field";
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
//@use "@material/textfield";

// Data Table
@use "@material/checkbox/mdc-checkbox"; // Required only for data table with row selection.
@use "@material/data-table/mdc-data-table";

// lists
@use "@material/list";

// https://github.com/material-components/material-components-web/tree/master/packages/mdc-image-list
// image list
@use "@material/image-list/mdc-image-list";
@use "@material/image-list";

// CHIPS

// @use "@material/chips/mdc-chips";
//

// elevation
@use "@material/elevation/mdc-elevation";

// drawer:
@use "@material/drawer/mdc-drawer";
@use "@material/list/mdc-list";

// menu
//@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";

//slider
@use "@material/slider/styles" as slider-style;

//tooltip
@use "@material/tooltip/styles"as tooltip-style;

@use "@material/animation";

@use "@material/linear-progress";


@use "@material/dialog";

@include dialog.core-styles;

.image-list-masonry-columns-9{
  @include image-list.masonry-columns(9);
}
.image-list-masonry-columns-12{
  @include image-list.masonry-columns(12);
}

// https://github.com/material-components/material-components-web/tree/master/packages/mdc-linear-progress
@include linear-progress.core-styles;
//@import '@material/linear-progress/dist/mdc.linear-progress.css';


